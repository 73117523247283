.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 52px;
  font-weight: bold;
  line-height: 70px;
  height: 70px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 70px;
}

.square.clickable:hover {
  border: 2px solid;
  border-color: coral;
}

.square:focus {
  outline: none;
}

.square.highlight {
  border: solid 2px rgb(130, 216, 0);
}
